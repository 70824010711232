import { createApp } from 'vue'
import App from './App.vue'
//import { createRouter, createWebHistory } from 'vue-router'
//For Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUserGraduate} from "@fortawesome/free-solid-svg-icons";
import { faCertificate} from "@fortawesome/free-solid-svg-icons";
import { faGear} from "@fortawesome/free-solid-svg-icons";
import { faLightbulb} from "@fortawesome/free-solid-svg-icons";
import { faBriefcase} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons"; //icon-embed2
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faCodeFork } from "@fortawesome/free-solid-svg-icons";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faGears } from "@fortawesome/free-solid-svg-icons";



library.add(faUser);
library.add(faUserGraduate);
library.add(faCertificate);
library.add(faGear);
library.add(faLightbulb);
library.add(faBriefcase);
library.add(faCode);
library.add(faDatabase);
library.add(faCodeFork);
library.add(faServer);
library.add(faDesktop);
library.add(faGears);
//For Routing

// import ProfileView from './components/Profile.vue'



// const router = createRouter({
//     history: createWebHistory(),
//     routes: [
//         { path: '/', component: ProfileView },
       
//     ]
// });


 const app = createApp(App)

 //app.use(router);

 app.component("font-awesome-icon", FontAwesomeIcon)

 app.mount('#app')


//  createApp(App)
//  //.use(router)
//  .component("font-awesome-icon", FontAwesomeIcon)
//  .mount('#app')
