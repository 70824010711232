<template>

 <div class="sub-section">
 <div class="tech-section-header"><font-awesome-icon class="fonticon" icon="server" size="1x" /><b>Web Servers</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/webservers/apache.webp"/>
      <p class="no_margin tech-content-description">Apache</p>
    </div>
<div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/webservers/iis.webp"/>
      <p class="no_margin tech-content-description">IIS</p>
    </div>

<div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/webservers/tomcat.webp"/>
      <p class="no_margin tech-content-description">Tom Cat</p>
    </div>

<div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/webservers/xampp.webp"/>
      <p class="no_margin tech-content-description">Xampp</p>
    </div>

   
   </div>
  
</div>
</template>

<script>
export default {
  name: "WebserverSubComponent"
};
</script>

<style scoped>
</style>
