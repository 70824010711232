<template>
<section class="section">
  <div class="section-header"><font-awesome-icon class="fonticon" icon="user" size="1x" /> <b>Profile </b>
  </div>
  <p class="profile-content">
    An experienced software developer with an insight in a variety of designing, developing of windows, web and mobile applications. 
    Organized, disciplined, career focused, highly educated, very keen eye for detailed results in solid coding and a dedicated and hardworking individual. 
    I possess a great communication skills both verbal and writing intertwined with an excellent ability to build rapport quickly with both clients and colleagues. 
    Possessing a positive and uplifting attitude towards life and career, combined with professional and continual self-improvement pursuits will provide to be a benefit to any organization.
    </p>
    <p class="profile-content">
                              Profile in Other Frameworks
                                <table cellpadding="10" style="margin: 0 auto;">
                                    <tbody>
                                        <tr><td class="tech-content-description">Regular:</td><td><a href="https://goutham731.com" target="_blank">https://goutham731.com</a></td></tr>
                                        <tr><td class="tech-content-description">Vue JS:</td><td><a href="https://vue.goutham731.com" target="_blank">https://vue.goutham731.com</a></td></tr>
                                        <tr><td class="tech-content-description">ASP MVC:</td><td><a href="https://aspmvc.goutham731.com" target="_blank">https://aspmvc.goutham731.com</a></td><td>(Hosted in home server expect delay in latency)</td></tr>
                                    </tbody>
                                </table>

                            </p>
    </section>
</template>

<script>
export default {
  name: 'ProfileView'
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
 @import '../../src/assets/styles/css/profileview.css';
</style>
