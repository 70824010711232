<template>
<section id="WorkExperience" class="section padding-down text-align-left">
                        <h2 class="section-header margin-top"><font-awesome-icon class="fonticon" icon="briefcase" size="1x" />  <b>Work Experience </b></h2>
                        <section id="work--NTT" class="sub-section ">

                            <div class="job-header">
                                <table>
                                    <tr>
                                        <td><img loading="lazy" alt="Carrier Logo" src="../../src/assets/images/Carrier.webp"
                                                class="carrier-log" /></td>
                                        <td><i>Carrier Global</i></td>
                                    </tr>
                                </table>

                            </div>
                            <div class="margin">
                                <p><strong>Role:</strong> Sr Software Architect/ Developer <br /> <strong> Duration:
                                    </strong> August 2018 - February 2020 </p>
                                                                                      
                                                <strong>Lead Activities</strong>
                                                <ul class="list-style">
                                                    <li>
                                                        Oversaw the operations and maintenance of software applications
                                                        and database owned by the Team.
                                                    </li>
                                                    <li>Lead selection of the appropriate technology for the future
                                                        developments</li>
                                                    <li>Managed a Team of developers (both database and Software)</li>
                                                    <li>
                                                        Worked closely with clients throughout the development cycle
                                                        ensuring proposed design met
                                                        customer requirements
                                                    </li>
                                                    <li>Helping the team members with the design and development process
                                                    </li>
                                                    <li>Enforced a process that did not existed before which helps to
                                                        solve production issues quicker</li>
                                                    <li>Assigned projects to the team members</li>
                                                    <li>Auditing Developers work</li>
                                                    <li>Implementing Developer changes to production environment</li>
                                                    <li>Ensured Team is following proper SDLC process and standard
                                                        practices</li>
                                                    <li>Maintain existing code in the code repositories</li>
                                                    <li>Participated in Server migrations, upgrades and validate the
                                                        changes</li>
                                                    <li>Ensured old technologies migrated to new technology</li>
                                                </ul>
                                      

                                           
                                                <strong>Development</strong>                                                
                                                        <ul class="list-style">
                                                            <li>
                                                                Developed a custom framework (Costing Tool) on top of
                                                                ASP.NET MVC which helps the client to cost the product
                                                                which contains different configuration options.
                                                            </li>
                                                            <li>Used C# as a programming language</li>
                                                            <li>Used JavaScript on client side for web applications</li>
                                                            <li>Made enhancements to existing applications, and for new
                                                                requests</li>
                                                            <li>Used Bootstrap for user interface development</li>
                                                            <li>Used Dapper and Entity Framework for Database
                                                                connectivity</li>
                                                            <li>Can generate a PDF report of the final cost for future
                                                                references</li>
                                                            <li>New products can be added and modified in the future
                                                                from Admin section of the application</li>
                                                            <li>Developed new web pages for existing ASP.NET MVC
                                                                applications</li>
                                                            <li>
                                                                Developed a windows application that updates Week, Month
                                                                and Quarter runs once a week which carries the previous
                                                                week units to next week production.
                                                            </li>
                                                            <li>
                                                                Developed a Signal R application used to notify
                                                                remaining time to the assembly technicians on the floor
                                                            </li>
                                                            <li>Used ASP Web API to deliver data in JSON format.</li>
                                                       
                                                            <li>Designed, developed, and maintained databases</li>
                                                            <li>Developed and optimized stored procedure and functions
                                                                using T-SQL</li>
                                                            <li>Developed SSIS packages for converting flat files and
                                                                XML files that delivers from SAP and MRP Systems to SQL
                                                                Server</li>
                                                            <li>Developed reports that generates periodically for
                                                                summary of completed products</li>
                                                            <li>Created schema for separate product lines</li>
                                                            <li>Created SQL Server Agent Jobs for executing SSIS
                                                                packages at regular intervals</li>
                                                       
                                                            <li>
                                                                Crated Excel Add-In to extract the data from different
                                                                sheets to one using C#
                                                            </li>
                                                            <li>
                                                                Created Excel Add-in to extract the data into relational
                                                                tables using C#
                                                   </li>
                                                        </ul>
                                                    
                                                                                           
                                                                     
                            </div>
                        </section>
                        <section id="work--Astir" class="sub-section">
                            <div class="job-header">
                                <table>
                                    <tr>
                                        <td><img loading="lazy" alt="Wells Fargo Logo"
                                                src="../../src/assets/images/wells-fargo-logo.webp" class="wells-fargo" /></td>
                                        <td><i>Wells Fargo</i></td>
                                    </tr>
                                </table>

                            </div>
                            <div class="margin">
                                <p><strong>Role:</strong> Full Stack .Net Developer <br /> <strong> Duration: </strong>
                                    October 2014 - October 2016 and April 2017 - January 2018 </p>
                                                                       <ul class="list-style">
                                            <li>Technical knowledge of electronic trading application.</li>
                                            <li>Migrated Java application to .Net using C#</li>
                                            <li>Created Console application to calculate business dates, weekdays, next
                                                available dates</li>
                                            <li>Used Reflections to change application behavior</li>
                                            <li>Used Dev Express for creating user interface for windows applications
                                            </li>
                                            <li>Designed tables and views structure for the application</li>
                                            <li>Used PL/SQL for querying database using SQL developer</li>
                                            <li>Created DDL’s scripts to create and modify the structure of database
                                                objects.</li>
                                            <li>Created DML’s scripts to manipulate the structures of database</li>
                                            <li>Used Dev Express for creating UI for Windows applications</li>
                                            <li>Used Datasets to store many Data Tables in single collection</li>
                                            <li>Created Autosys jobs for scheduled events.</li>
                                            <li>Engaged with Implementation team for Production related deliveries</li>
                                            <li>Created Change Request, Work requests and Problem tickets using BMC
                                                Remedy PAC 2000.</li>
                                            <li>Used SVN and GitHub for Code repository</li>
                                            <li>Migrated source code from SVN to GitHub</li>
                                            <li>Used Confluence for documentations.</li>
                                            <li>Created Flow Charts for documenting application flow</li>
                                            <li>Participated in Application design meetings and code reviews</li>
                                            <li>Migrated Cisco CTI-OS VB6 Winforms application to WPF using C#</li>
                                            <li>Migrated Cisco CTI-OS VB.Net Winforms applications to WPF using C#</li>
                                            <li>Propose solutions to customer’s requirements and provide estimates for
                                                development</li>
                                            <li>Developed gadgets for Cisco Finesse web application using JavaScript and
                                                XML.</li>
                                            <li>Wrote custom application for agent events using Cisco UCCX CTI Server in
                                                WCF.</li>
                                            <li>Wrote custom code for Genesys Interaction Workspace in WPF.</li>
                                            <li>Developed WCF service to subscribe agent events for both genesys and
                                                cisco platforms.</li>
                                            <li>Wrote custom code for Wells Fargo proprietary softphone.</li>
                                            <li>Developed web applications for business to make changes in Genesys
                                                configuration server in MVC 4 and User Interface using Bootstrap</li>
                                            <li>Developed WPF application for opening a PAC 2000 Remedy ticket with the
                                                customer</li>
                                            <li>information when the agent entered the eGain Chat session and sending
                                                transcript of the chat after the session ended.</li>
                                            <li>Utilized Web services as middleware and SQL server in backend and
                                                Developed Microsoft Clickonce applications.</li>
                                            <li>XML and SOAP was used to exchange structured and typed information with
                                                help of web service over the internet.</li>
                                            <li>Used ADO.NET for SQL Server database interactions using Data Adaptors,
                                                Data Reader and Dataset.</li>
                                            <li>T- SQL was extensively used to query the database.</li>
                                            <li>WCF was designed with Service oriented architecture (SOA) to support
                                                Distributed</li>
                                            <li>computing where services were consumed by end users.</li>
                                            <li>Wrote client-side validations using jQuery.</li>
                                            <li>Worked on Proof of Concepts and demonstration of products in support of
                                                the company's own development methods.</li>
                                            <li>Maintained relations with Line of business (LOB), attended Joint
                                                application Design (JAD) sessions</li>
                                            <li>Created Production Implementation documents and designed Flowcharts</li>
                                            <li>Used Apache Subversion (SVN) for code repository.</li>
                                        </ul>
                            </div>
                        </section>
                        <section id="work--Avco" class="sub-section">
                            <div class="job-header">
                                <table>
                                    <tr>
                                        <td><img loading="lazy" alt="First Data Logo" src="../../src/assets/images/first-data.webp"
                                                class="first-data" /></td>
                                        <td><i>First Data</i></td>
                                    </tr>
                                </table>
                            </div>

                            <div class="margin">
                                <p><strong>Role:</strong> C# Developer <br /> <strong> Duration: </strong> September
                                    2013 - August 2014</p>

                                                                       <ul class="list-style">
                                            <li>Worked on Interactive Voice Response development</li>
                                            <li>Migrated Legacy Conversant application to .Net Environment using C#</li>
                                            <li>Migrated legacy ASP applications to ASP.Net</li>
                                            <li>Comprehended Conversant code and designed flowcharts</li>
                                            <li>Utilized web services as middle ware and SQL server in the backend</li>
                                            <li>Worked with database professionals to develop and implement secure, fast
                                                and stable data structures.</li>
                                            <li>Created Voice User Interface (VUI) for IVR applications.</li>
                                            <li>Wrote client-side validations using JavaScript.</li>
                                            <li>Used TFS for code repository.</li>
                                        </ul>
                            </div>
                        </section>

                    </section>
</template>

<script>
export default {
  name: 'WorkExperienceComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
 
</style>
