<template>

 <div class="sub-section">
 <div class="tech-section-header"><font-awesome-icon class="fonticon" icon="code" size="1x" /> <b>Programming Skills</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/csharp.svg"/>
      <p class="no_margin tech-content-description">C Sharp</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/c.svg"/>
      <p class="no_margin tech-content-description">C</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/cplusplus.svg"/>
      <p class="no_margin tech-content-description">C++</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/html-five.svg"/>
      <p class="no_margin tech-content-description">HTML 5</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/javascript.svg"/>
      <p class="no_margin tech-content-description">Java Script</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/php.svg"/>
      <p class="no_margin tech-content-description">PHP</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/python.svg"/>
      <p class="no_margin tech-content-description">Python</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/languages/css3.svg"/>
      <p class="no_margin tech-content-description">CSS3</p>
    </div>
   
   </div>
  
</div>
</template>

<script>
export default {
  name: "ProgrammingSkillsSubComponent"
};
</script>

<style scoped>
</style>
