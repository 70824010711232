<template>
<div>
<AppHeader/>
<AppContent/>
<MobileAppComponent/>
</div>
</template>

<script>
import AppContent from './components/AppContent.vue'
import AppHeader from './components/AppHeader.vue'
import MobileAppComponent from './components/MobileAppComponent'

export default {
  name: 'App',
   components: {
    AppContent,AppHeader,
    MobileAppComponent
  }
}
</script>

<style>

body{
    margin-left: 0;
    margin-top: 0;
}

/* #app {
  display: grid;
  grid-template-columns: 10% auto;
} */
  @import './assets/styles/css/w3.css';
  @import './assets/styles/css/global_variables.css';
  @import './assets/styles/css/AppContent.css';
 @import './assets/styles/css/Menu.css';
 @import './assets/styles/css/CollegeAcademics.css';
 @import './assets/styles/css/Certificates.css';
 @import './assets/styles/css/AppHeader.css';
 @import './assets/styles/css/TechnicalSection.css';
 @import './assets/styles/css/Expertise.css';
 @import './assets/styles/css/WorkExperience.css';
 @import './assets/styles/css/MobileMenu.css';
 
</style>
