<template>
  <div id="Content">
  <div class="menuClass">
  <div @click="activeComp = 'ProfileView'" class="w3-card"> <font-awesome-icon icon="user" size="1x" /><p class="no_margin"><b>Profile</b></p> </div>
  <div @click="activeComp = 'CollegeAcademics'" class="w3-card"><font-awesome-icon icon="user-graduate" size="1x" /><p class="no_margin"><b>Education</b></p></div>
  <div @click="activeComp = 'CertificatesComponent'" class="w3-card"><a><font-awesome-icon icon="certificate" size="1x" /><p class="no_margin"><b>Certifications</b></p></a></div>
  <div @click="activeComp = 'TechnicalSkillsComponent'" class="w3-card"><a><font-awesome-icon icon="gear" size="1x" /><p class="no_margin"><b>Technical Skills</b></p></a></div>
  <div @click="activeComp = 'ExpertiseComponent'" class="w3-card"><a><font-awesome-icon icon="lightbulb" size="1x"/> <p class="no_margin"><b>Expertise</b></p></a></div>
  <div @click="activeComp = 'WorkExperienceComponent'" class="w3-card"><a><font-awesome-icon icon="briefcase" size="1x"/><p class="no_margin"><b>Work Experience</b></p></a></div>
  </div>       
 <component :is="activeComp"></component>
 </div>

</template>

<script>
import ProfileView from "./ProfileView.vue";
import CollegeAcademics from "./CollegeAcademics.vue";
import CertificatesComponent from "./CertificatesComponent.vue";
import TechnicalSkillsComponent from "./TechnicalSkillsComponent.vue";
import ExpertiseComponent from "./ExpertiseComponent.vue";
import WorkExperienceComponent from "./WorkExperienceComponent.vue";

export default {
  name: "AppContent",
  components: {
    ProfileView,
    CollegeAcademics,
    CertificatesComponent,
    TechnicalSkillsComponent,
    ExpertiseComponent,
    WorkExperienceComponent
  },
  data() {
    return {
      activeComp: "ProfileView"
    };
  },
  methods: {
    openNav() {
      document.getElementById("mobileNav").style.display = "block";
    },
    closeNav() {
      document.getElementById("mobileNav").style.display = "none";
    }
  }
};
</script>

<style>
</style>
