<template>

 <div class="sub-section">
 <div class="tech-section-header"><font-awesome-icon class="fonticon" icon="database" size="1x" /> <b>Database</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/DbServers/mariadb.svg"/>
      <p class="no_margin tech-content-description">Maria DB</p>
    </div>
   
   <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/DbServers/mysql.svg"/>
      <p class="no_margin tech-content-description">My SQL</p>
    </div>
   
   <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/DbServers/postgresql.svg"/>
      <p class="no_margin tech-content-description">Postgre SQL</p>
    </div>
   
   <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/DbServers/microsoftaccess.svg"/>
      <p class="no_margin tech-content-description">MS Access</p>
    </div>
   
     <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/DbServers/MS_Sql_Server.webp"/>
      <p class="no_margin tech-content-description">MS Access</p>
    </div>
   </div>
  
</div>
</template>

<script>
export default {
  name: "DbServersSubComponent"
};
</script>

<style scoped>
</style>
