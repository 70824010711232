<template>
<div id="mobileNav">
 <div id="mobileMenu"  class="overlay">
  <div href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</div>
  <div class="overlay-content">
  <div @click="activeComp = 'ProfileView'; closeNav()" class="w3-card"> <font-awesome-icon icon="user" size="1x" /><p class="no_margin"><b>Profile</b></p> </div>
  <div @click="activeComp = 'CollegeAcademics'; closeNav()" class="w3-card"><font-awesome-icon icon="user-graduate" size="1x" /><p class="no_margin"><b>Education</b></p></div>
  <div @click="activeComp = 'CertificatesComponent'; closeNav()" class="w3-card"><a><font-awesome-icon icon="certificate" size="1x" /><p class="no_margin"><b>Certifications</b></p></a></div>
  <div @click="activeComp = 'TechnicalSkillsComponent'; closeNav()" class="w3-card"><a><font-awesome-icon icon="gear" size="1x" /><p class="no_margin"><b>Technical Skills</b></p></a></div>
  <div @click="activeComp = 'ExpertiseComponent'; closeNav()" class="w3-card"><a><font-awesome-icon icon="lightbulb" size="1x"/> <p class="no_margin"><b>Expertise</b></p></a></div>
  <div @click="activeComp = 'WorkExperienceComponent'; closeNav()" class="w3-card"><a><font-awesome-icon icon="briefcase" size="1x"/><p class="no_margin"><b>Work Experience</b></p></a></div>
  </div>
</div>
<div @click="openNav()" class="menuTextDisplay" >Menu</div>

<component :is="activeComp"></component>
</div>
</template>

<script>

import ProfileView from "./ProfileView.vue";
import CollegeAcademics from "./CollegeAcademics.vue";
import CertificatesComponent from "./CertificatesComponent.vue";
import TechnicalSkillsComponent from "./TechnicalSkillsComponent.vue";
import ExpertiseComponent from "./ExpertiseComponent.vue"
import WorkExperienceComponent from "./WorkExperienceComponent.vue"

export default {
  name: 'MobileMenuComponent', components: {
    ProfileView,
    CollegeAcademics, CertificatesComponent,TechnicalSkillsComponent,ExpertiseComponent,WorkExperienceComponent
  },
  data() {
    return {
      activeComp: "ProfileView"
    };
  },
  methods: {
    openNav() {
      document.getElementById("mobileMenu").style.display = "block";
      document.getElementById("mobileMenu").style.width = "100%";
      
    },
    closeNav() {
      document.getElementById("mobileMenu").style.display = "none";
    }
  }
}


</script>

<style>

</style>
