<template>

 <div class="sub-section">
 <div class="tech-section-header"><font-awesome-icon class="fonticon" icon="code-fork" size="1x" /><b>Version Control</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/VersionControls/github.webp"/>
      <p class="no_margin tech-content-description">Git Hub</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/VersionControls/svn.webp"/>
      <p class="no_margin tech-content-description">SVN</p>
    </div>
  
   </div>
  
</div>
</template>

<script>
export default {
  name: "VersionControlSubComponent"
};
</script>

<style scoped>
</style>
