<template>

 <div class="sub-section">
 <div class="tech-section-header"><font-awesome-icon class="fonticon" icon="code-fork" size="1x" /><b>.Net Framework</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/ado_logo.webp"/>
      <p class="no_margin tech-content-description">ADO .NET</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/ASP.NET-MVC.webp"/>
      <p class="no_margin tech-content-description">ASP .Net</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/Blazor.webp"/>
      <p class="no_margin tech-content-description">Blazor</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/Entity-Framework.webp"/>
      <p class="no_margin tech-content-description">Entity Framework</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/log4net.webp"/>
      <p class="no_margin tech-content-description">Log 4 Net</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/Maui.webp"/>
      <p class="no_margin tech-content-description">Maui</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/WPF.webp"/>
      <p class="no_margin tech-content-description">WPF</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/NHibernate-logo.webp"/>
      <p class="no_margin tech-content-description">NHibernate</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/Nlog_logo.webp"/>
      <p class="no_margin tech-content-description">Nlog</p>
    </div>
<!--     <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/prism-library-logo.webp"/>
      <p class="no_margin tech-content-description">Prism library</p>
    </div> -->
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/wcf_logo.webp"/>
      <p class="no_margin tech-content-description">WCF</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/winform-logo.webp"/>
      <p class="no_margin tech-content-description">Win Forms</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/Xamarin_Logo.webp"/>
      <p class="no_margin tech-content-description">Xamarin</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/netframeworks/Xaml_Logo.webp"/>
      <p class="no_margin tech-content-description">XAML</p>
    </div>

  
   
   </div>
  
</div>
</template>

<script>
export default {
  name: "DotnetSubComponent"
};
</script>

<style scoped>
</style>
