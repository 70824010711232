<template>
<section class="section">
  <div class="section-header"><font-awesome-icon class="fonticon" icon="user-graduate" size="1x" /> <b>Education</b>
  </div>
  <div id="Education" >
 <div >
    <a href="https://fairfield.edu" class="no_under_line" target="_blank">
     <img class="EducationImage" src="../../src/assets/images/academics/fairfield.webp"/>
     <br/><p class="no_margin"><strong>Master's of Science in Engineering</strong></p>
     <p class="no_margin"><strong> 2011 - 2013</strong></p>
    </a>
  </div>
  <div >
    <a href="https://badges.wes.org/Evidence?i=f0aed70f-711e-4a67-b1c8-95ba3e8969ec&type=ca" target="_blank" class="no_under_line">
    <img class="EducationImage" src="../../src/assets/images/academics/wes.webp"/>
    <p class="no_margin"><i>click to verify</i></p>
    </a>
  </div>
</div>
</section>
</template>


<script>
export default {
  name: 'CollegeAcademics'
   }

</script>

<style scoped>



</style>
