<template>
<section class="section">
  <div class="section-header"><font-awesome-icon class="fonticon" icon="certificate" size="1x" /> <b>Certificates</b>
  </div>
  <div id="Certificates" >
  <div>
    <a href="https://www.youracclaim.com/badges/7be2ce8c-9f61-439b-8e0c-415b3c7246fb/public_url" target="_blank" name="MCSD" type="Link">
    <img alt="mcsd certificate logo" src="../../src/assets/images/certificates/mcsd-app-builder.webp" class="certificate-image"
    ><br>
    <span>Click to verfiy</span>
    </a>
    </div>

    <div>
      <a href="https://www.youracclaim.com/badges/f6423c97-882a-4531-9186-e323ddd33bd2/public_url" target="_blank" name="MCSA" type="Link">
      <img loading="lazy" alt="mcsa certificate logo" src="../../src/assets/images/certificates/mcsa-web-applications.webp" class="certificate-image"><br>
      <span >Click to verfiy</span>
      </a>
    </div>

   <div>
     <a href="https://www.youracclaim.com/badges/6780684d-a1f4-41f7-9678-1db53d50c39f/public_url" target="_blank" name="Web Services" type="Link">
     <img loading="lazy" alt="exam 487 logo" src="../../src/assets/images/certificates/exam-487.webp" class="certificate-image"><br
     ><span >Click to verfiy</span>
     </a>
     </div>

     <div><a href="https://www.youracclaim.com/badges/757c54a2-8988-4eb1-87a9-b9607d1de690/public_url" target="_blank" name="MVC" type="Link">
     <img loading="lazy" alt="exam 486 logo" src="../../src/assets/images/certificates/exam-486.webp" class="certificate-image"><br>
     <span >Click to verfiy</span>
     </a>
     </div>

     <div><a href="https://www.youracclaim.com/badges/d6a8c9fc-fdc1-4326-a003-4e74af07f129/public_url" target="_blank" name="C-Sharp" type="Link">
     <img loading="lazy" alt="exam 483 logo" src="../../src/assets/images/certificates/exam-483.webp" class="certificate-image"><br>
     <span >Click to verify

     </span>
     </a>
     </div>

     <div>
      <img alt="ciw JavaScript logo" loading="lazy" src="../../src/assets/images/certificates/javascript_sp.webp" class="certificate-image">
      </div>
  
</div>
</section>
</template>


<script>
export default {
  name: 'CertificatesComponent'
}
</script>

<style scoped>



</style>
