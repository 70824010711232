<template>
<section class="section">
 <div class="section-header"><font-awesome-icon class="fonticon" icon="gear" size="1x" /> <b>Technical Skills</b>
  </div>
  <ProgrammingSkillsSubComponent/>
  <DbServersSubComponent/>
  <JsLibrariesSubComponent/>
  <DotnetSubComponent/>
  <WebserverSubComponent/>
  <VersionControlSubComponent/>
  <OperatingSystemsSubComponent/>
  <OtherSubComponents/>
</section>
</template>

<script>
import ProgrammingSkillsSubComponent from './sub_components/ProgrammingLanguagesSubComponent.vue';
import DbServersSubComponent from './sub_components/DbServersSubComponent.vue'
import JsLibrariesSubComponent from './sub_components/JsLibrariesSubComponent.vue'
import DotnetSubComponent from './sub_components/DotnetSubComponent.vue'
import WebserverSubComponent from './sub_components/WebserverSubComponent.vue'
import VersionControlSubComponent from './sub_components/VersionControlSubComponent.vue'
import OperatingSystemsSubComponent from './sub_components/OperatingSystemsSubComponent.vue'
import OtherSubComponents from './sub_components/OtherSubComponents.vue'
export default {
  name: 'TechnicalSkillsComponent',
  components: {
    ProgrammingSkillsSubComponent,DbServersSubComponent,JsLibrariesSubComponent,DotnetSubComponent,
    WebserverSubComponent,VersionControlSubComponent,OperatingSystemsSubComponent,OtherSubComponents
  },
}
</script>

<style scoped>
 
</style>
