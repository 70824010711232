<template>

 <div class="sub-section">
 <div class="tech-section-header"><img src="../../../src/assets/images/TechnicalSkills/languages/javascript.svg"/> <b>JavaScript Libraries/Frameworks</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/Backbone.svg"/>
      <p class="no_margin tech-content-description">Backbone JS</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/bootstrap.svg"/>
      <p class="no_margin tech-content-description">BootStrap</p>
    </div>
     <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/d3-dot-js.svg"/>
      <p class="no_margin tech-content-description">D3</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/gulp.svg"/>
      <p class="no_margin tech-content-description">Gulp JS</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/Handlebars.svg"/>
      <p class="no_margin tech-content-description">Handlebars</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/jquery.svg"/>
      <p class="no_margin tech-content-description">Jquery</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/node-dot-js.svg"/>
      <p class="no_margin tech-content-description">Node Js</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/sass.svg"/>
      <p class="no_margin tech-content-description">Sass</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/typescript.svg"/>
      <p class="no_margin tech-content-description">Type Script</p>
    </div>
     <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/JsLibraries/vuejs.svg"/>
      <p class="no_margin tech-content-description">Vue JS</p>
    </div>
      
   </div>
  
</div>
</template>

<script>
export default {
  name: "JsLibrariesSubComponent"
};
</script>

<style scoped>
</style>
