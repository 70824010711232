<template>

  <div>
    <table>
                <tr>
                    <td>
                        <img alt="Goutham Inamanamelluri" src="../../src/assets/images/profile/Profile-Image.webp"
                            class="white-box-shadow profile-image" />
                    </td>
                    <td class="base-align text-align-left padding">
                        <strong>
                          <br/>
                          <br/>
                          <br/>
                          <br/>
                            <p class="no_margin profile-name">
                                Goutham Inamanamelluri 
                                <br/>
                                goutham@goutham731.com 
                            </p>
                        </strong>
                    </td>
                </tr>
            </table>
    </div>
</template>

<script>
export default {
  name: "AppHeader"
};
</script>

<style scoped>
</style>
