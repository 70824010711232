<template>

 <div class="sub-section">
 <div class="tech-section-header"><font-awesome-icon class="fonticon" icon="gears" size="1x" /><b>Other</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/excel.webp"/>
      <p class="no_margin tech-content-description">MS Excel</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/outlook.webp"/>
      <p class="no_margin tech-content-description">Outlook</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/powerpoint.webp"/>
      <p class="no_margin tech-content-description">MS Power Point</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/visio.webp"/>
      <p class="no_margin tech-content-description">MS Visio</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/word.webp"/>
      <p class="no_margin tech-content-description">MS Word</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/visualstudio.svg"/>
      <p class="no_margin tech-content-description">Visual Studio</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/visualstudiocode.svg"/>
      <p class="no_margin tech-content-description">VS Code</p>
    </div>
  <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/other/microsoftonenote.svg"/>
      <p class="no_margin tech-content-description">MS One Note</p>
    </div>
  
   </div>
  
</div>
</template>

<script>
export default {
  name: "OtherSubComponents"
};
</script>

<style scoped>
</style>
