<template>

 <div class="sub-section">
 <div class="tech-section-header"><font-awesome-icon class="fonticon" icon="desktop" size="1x" /><b>Operating Systems</b>
  </div>
 <div class="tech-section-content" > 
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/os/macos.webp"/>
      <p class="no_margin tech-content-description">Mac OS</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/os/ubuntu.webp"/>
      <p class="no_margin tech-content-description">Ubuntu</p>
    </div>
    <div>
      <img class="tech-content-image" src="../../../src/assets/images/TechnicalSkills/os/windows.webp"/>
      <p class="no_margin tech-content-description">Windows</p>
    </div>
  
   </div>
  
</div>
</template>

<script>
export default {
  name: "OperatingSystemsSubComponent"
};
</script>

<style scoped>
</style>
