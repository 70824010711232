<template>
  <section id="expertise" class="section">
                        <div class="section-header margin-top"><font-awesome-icon class="fonticon" icon="lightbulb" size="1x" /> <b>Expertise</b></div>

                        <div class="donut-flex-container">
                            <div>
                                <img alt="C Sharp Donut Chart" loading="lazy" src="../../src/assets/images/charts/cs.webp"
                                    class="donut-image" /><br /><b><span class="tech-content-description">C#</span></b>
                            </div>
                            <div>
                                <img alt="JavaScript Donut Chart" loading="lazy" src="../../src/assets/images/charts/JavaScript.webp"
                                    class="donut-image" /><br /><b><span class="tech-content-description">JavaScript</span></b>
                            </div>
                            <div>
                                <img alt="HTML Donut Chart" loading="lazy" src="../../src/assets/images/charts/HTML.webp"
                                    class="donut-image" /><br /><b><span class="tech-content-description">HTML5, CSS3</span></b>
                            </div>
                            <div>
                                <img alt="SQL Donut Chart" loading="lazy" src="../../src/assets/images/charts/SQL.webp"
                                    class="donut-image" /><br /><b><span class="tech-content-description">SQL</span></b>
                            </div>

                        </div>

                        <div class="skills-div-title">

                            <table class="skills">
                                <tr class="margin-bottom padding">
                                    <td class="skills-td-title box-shadow">
                                        Winforms
                                    </td>
                                    <td class="skill-td-bar box-shadow">
                                        <div class="skills-div" style="width:99%;">99%</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="skills-td-title box-shadow">
                                        WPF
                                    </td>

                                    <td class="skill-td-bar box-shadow">
                                        <div class="skills-div" style="width:95%;">95%</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="skills-td-title box-shadow">
                                        MVC
                                    </td>
                                    <td class="skill-td-bar box-shadow">
                                        <div class="skills-div" style="width:93%;">93%</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="skills-td-title box-shadow">
                                        Xamarin
                                    </td>
                                    <td class="skill-td-bar box-shadow">
                                        <div class="skills-div" style="width:95%;">95%</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="skills-td-title box-shadow">
                                        Signal-R
                                    </td>
                                    <td class="skill-td-bar box-shadow">
                                        <div class="skills-div" style="width:90%;">90%</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="skills-td-title box-shadow">
                                        Blazor
                                    </td>
                                    <td class="skill-td-bar box-shadow">
                                        <div class="skills-div" style="width:81%;">81%</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="skills-td-title box-shadow">
                                        WCF
                                    </td>
                                    <td class="skill-td-bar box-shadow">
                                        <div class="skills-div" style="width:93%;">93%</div>
                                    </td>
                                </tr>
                            </table>
                        </div>

                    </section>
</template>

<script>
export default {
  name: "ExpertiseComponent"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
